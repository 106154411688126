<template>
  <div class="talentsData">
    <div class="topCard" style="margin-bottom: 20px">
      <el-card>
        <!-- 按字段查询 -->
        <el-form label-width="90px" class="ly-header__bg" style="margin: 0">
          <el-form-item label="领域类别：">
            <dic-tag-button
              :code.sync="queryInfo.fieldTagName"
              query-name="fieldTagName"
              name="领域类别"
              tag="领域"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="行业类别：">
            <dic-tag-button
              :code.sync="queryInfo.industryTagName"
              query-name="industryTagName"
              name="行业类别"
              tag="行业"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="技术类别：">
            <dic-tag-button
              :code.sync="queryInfo.solutionTechnologyName"
              query-name="solutionTechnologyName"
              name="技术类别"
              tag="技术"
              @onChange="onChange"
            />
          </el-form-item>
          <el-form-item label="筛选条件：" class="filterInfo">
            <el-tag
              closable
              v-for="(item,index) in tags"
              :key="index+item.name"
              @close="closeTag(index,item.field)"
            >
              {{item.name}}：{{queryInfo[item.field]?queryInfo[item.field]:'不限'}}
              <span
                class="circle"
                v-if="item.hasOwnProperty('valLength')&&item.valLength>0"
              >+{{item.valLength}}</span>
            </el-tag>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加案例 -->
          <el-button @click="addItem" type="primary" icon="el-icon-plus">添加</el-button>
          <import-data @fileClick="fileClick" moduleName="方案拆解" />
          <el-button @click="multDelete" style="margin: 0px">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color:#606266">按上传时间</div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'creation_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'creation_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <!-- 搜索框 -->
        <el-input
          placeholder="根据名称、标签搜索"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="getSchemes"
        >
          <template slot="append">
            <div @click="getSchemes" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        style="width:100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column prop="id" label="序号" align="center" width="100">
          <template v-slot="scope">
            <span>{{(queryInfo.pageNum-1)*queryInfo.pageSize+(scope.$index + 1)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="solutionDataIntegrity" label="数据完整度" sortable="custom">
          <template v-slot="{row}">
            <div @click="editTalent(row)" style="cursor:pointer;">
              <el-progress type="circle" :percentage="row.solutionDataIntegrity" :stroke-width="12"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="solutionName" label="方案名称">
          <template v-slot="{ row }">
            <div @click="editTalent(row)" style="cursor:pointer;">
              <span>{{row.solutionName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="comName" label="所属企业"></el-table-column>
        <el-table-column prop="fieldTag" label="领域类别" width="200">
          <template slot-scope="scope">
            <div v-if="(scope.row.fieldTag !== null) && (scope.row.fieldTag !== '')">
                  {{scope.row.fieldTag.substring(0,20)}}
                  <span v-if="scope.row.fieldTag.length>20">...</span>
                </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="fieldId" label="领域类别" style="color:blue" width="200">
          <template v-slot="{row}">
            <span class="tableColor">{{row.fieldId}}</span>
          </template>
        </el-table-column>-->
        <el-table-column prop="industryTag" label="行业类别" width="200">
          <template slot-scope="scope">
            <div v-if="(scope.row.industryTag !== null) && (scope.row.industryTag !== '')">
                  {{scope.row.industryTag.substring(0,20)}}
                  <span v-if="scope.row.industryTag.length>20">...</span>
                </div>
          </template>
        </el-table-column>
        <el-table-column prop="solutionTechnology" label="技术类别" width="200">
          <template slot-scope="scope">
            <div v-if="(scope.row.solutionTechnology !== null) && (scope.row.solutionTechnology !== '')">
                  {{scope.row.solutionTechnology.substring(0,20)}}
                  <span v-if="scope.row.solutionTechnology.length>20">...</span>
                </div>
          </template>
        </el-table-column>
        <el-table-column prop="solutionIntro" label="应用场景">
          <template
            slot-scope="scope"
            v-if="(scope.row.solutionIntro !== null) && (scope.row.solutionIntro !== '')"
          >
            {{ scope.row.solutionIntro.substring(0,20)}}
            <span
              v-if="scope.row.solutionIntro.length>20"
            >...</span>
          </template>
        </el-table-column>
        <el-table-column prop="adminName" label="创建人" align="center" width="80"></el-table-column>
        <el-table-column prop="creationTime" label="创建时间" :formatter="formatTime"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.id)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[10, 30, 50,100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import DicTagButton from '@/components/DicTagButton.vue'
import ImportData from '@/components/ImportData.vue'
import ButtonTable from '../../components/ButtonTable.vue'
import { getScheme, deleteScheme } from '@/api/scheme.js'
import DicRadioButton from '@/components/DicRadioButton.vue'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
export default {
  components: {
    ButtonTable,
    DicRadioButton,
    DicTagButton,
    DicCheckboxGroup,
    ImportData,
  },
  created() {
    this.getSchemes()
  },
  data() {
    return {
      tags: [
        {
          name: '领域类别',
          field: 'fieldTagName',
          valLength: 0,
        },
        {
          name: '行业类别',
          field: 'industryTagName',
          valLength: 0,
        },
        {
          name: '技术类别',
          field: 'solutionTechnologyName',
          valLength: 0,
        },
      ],
      // 查询搜索数据
      // 表格数据
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        order: 1,
        industryTagName: '',
        fieldTagName: '',
        columnName: 'creation_time,update_time',
        solutionTechnologyName: '',
        industryTag: [],
        fieldTag: [],
        solutionTechnology: [],
      },
      dataData: [],
      total: 0,
      // 搜索
      input2: '',
      // 删除
      deleteTalentData: [],
      multipleSelection: [],
      valueI: 0,
    }
  },
  methods: {
    // 按上传时间显示
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = 'creation_time'
          this.queryInfo.order = 1
          this.valueI++
          break
        case 1:
          this.queryInfo.columnName = 'creation_time'
          this.queryInfo.order = 0
          this.valueI++
          break
        case 2:
          this.queryInfo.columnName = 'creation_time,update_time'
          this.queryInfo.order = 1
          this.valueI = 0
          break
      }
      this.getSchemes()
    },
    // 浏览人才数据
    getSchemes1() {
      let queryConditions = this.queryInfo.queryConditions
      this.queryInfo = {
        queryConditions: queryConditions,
        pageSize: 10,
        pageNum: 1,
        order: 1,
      }
      this.getSchemes()
    },
    async getSchemes() {
      const res = await getScheme(this.queryInfo)
      if (res.code === 200) {
        this.dataData = res.data.list
        this.total = res.data.total
        this.dataData.forEach((el) => {
          if (el.solutionDataIntegrity) {
            el.solutionDataIntegrity = Number(
              el.solutionDataIntegrity.slice(0, -1)
            )
          } else {
            el.solutionDataIntegrity = 0
          }
        })
      }
    },
    // 按姓名搜索
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSchemes()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSchemes()
    },
    // 选中表格数据并赋值id选项
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.id
      })
    },
    // 跳转编辑页面
    editTalent(data) {
      // this.$router.push({
      //   name: 'schemeInfo',
      //   params: {
      //     personData: data.id,
      //     personType: 1,
      //   },
      // })
      let text = this.$router.resolve({
        path: "/schemeInfo",
        query: {
              personData: data.id,
          personType: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    // 单个删除
    deleteTalent(id) {
      this.deleteTalentData[0] = id
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示').then(
        async () => {
          const res = await deleteScheme(this.deleteTalentData)
          if (res.code == 200) {
            this.getSchemes()
            this.deleteTalentData = []
            this.$message.success('删除成功')
          }
        }
      )
    },
    // 批量删除
    multDelete() {
      if (this.multipleSelection.length > 1) {
        this.$confirm('此操作将永久删除批量文件, 是否继续?', '提示').then(
          async () => {
            const res = await deleteScheme(this.multipleSelection)
            if (res.code == 200) {
              this.getSchemes()
              this.multipleSelection = []
              this.$message.success('删除成功')
            }
          }
        )
      } else {
        this.$message.error('批量删除必须大于一条')
      }
    },
    // 批量上传
    handleClose() {
      this.$refs.checkboxEnterprise.clear()
    },
    addItem() {
      this.$router.push({
        name: 'schemeInfo',
        query: {
          personCode: '',
        },
      })
    },

    // 正序
    caretTop() {
      this.queryInfo.order = 1
      this.getSchemes()
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 0
      this.getSchemes()
    },
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = []

      Object.assign(this.queryInfo, { [queryName]: val })

      switch (name) {
        case '领域类别':
          this.queryInfo.fieldTag = [val]
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[0].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.fieldTag =
              this.queryInfo.fieldTag.concat(childrenVal)
          } else {
            this.tags[0].valLength = 0
          }

          if (this.queryInfo.fieldTag[0] == '') {
            this.queryInfo.fieldTag = []
          }

          break

        case '行业类别':
          this.queryInfo.industryTag = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[1].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.industryTag =
              this.queryInfo.industryTag.concat(childrenVal)
          } else {
            this.tags[1].valLength = 0
          }
          if (this.queryInfo.industryTag[0] == '') {
            this.queryInfo.industryTag = []
          }

          break

        case '技术类别':
          this.queryInfo.solutionTechnology = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[2].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.solutionTechnology =
              this.queryInfo.solutionTechnology.concat(childrenVal)
          } else {
            this.tags[2].valLength = 0
          }

          if (this.queryInfo.solutionTechnology[0] == '') {
            this.queryInfo.solutionTechnology = []
          }

          break

        default:
          break
      }

      if (!this.tags.some((item) => item.field === queryName)) {
        this.tags.push({
          name,
          field: queryName,
        })
      }

      this.getSchemes()
    },
    formatTime(row, column) {
        const date = new Date(row[column.property])
        return date.getFullYear() + '-' +
         (date.getMonth()+1)+ '-' +
         date.getDate()
    },
    closeTag(index, name) {
      this.tags.splice(index, 1)

      this.queryInfo[name] = ''
    },
    fileClick() {
      this.getSchemes()
    },
    sortChange(column) {
      this.pageNum = 1
      if (column.prop === 'solutionDataIntegrity') {
        this.queryInfo.columnName = 'solution_data_integrity'
        if (column.order === 'ascending') {
          this.queryInfo.order = 0
        } else if (column.order === 'descending') {
          this.queryInfo.order = 1
        } else {
          this.queryInfo.order = 1
          this.queryInfo.columnName = 'creation_time,update_time'
        }
      }
      this.getSchemes()
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  .upload_time {
    position: relative;
    color: #1f212b;
    font-size: 14px;
    margin-left: 20px;
    .el-icon-caret-top,
    .el-icon-caret-bottom {
      position: absolute;
      right: -16px;
      color: #1f212b;
    }
    .el-icon-caret-top {
      top: 0px;
    }
    .el-icon-caret-bottom {
      top: 6px;
    }
  }
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
.el-dropdown-menu {
  text-align: center;
  border: 1px solid #dbdbdb;
  width: 176px;
  height: 74px;
}
/deep/ .upload {
  padding: 12px 20px;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
.caretTime {
  cursor: pointer;
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
